<script lang="ts">
	import type { Publisher } from '@tickrr/db';

	import Image from './Image.svelte';

	export let path: null | string;
	export let title: string;
	export let alt: string = '';
	export let classes: string = '';
	export let imgClasses: string = '';
	export let height: string = 'h-full';
	export let width: string = 'w-[min(100%,40rem)]';
	export let rounded: string = 'rounded-token';
	export let mode: 'contain' | 'cover' | undefined = undefined;
	export let publisher: Pick<Publisher, 'name' | 'username'> | undefined = undefined;
</script>

<figure
	data-item-title={title}
	data-publisher-name={publisher?.name}
	data-publisher-username={publisher?.username}
	data-testid="feed-item__image"
	class="
		aspect-video
		{height}
		{width}
		{rounded}
		{classes}
		"
>
	<Image
		{alt}
		classes="h-full w-full select-none bg-surface-50 {imgClasses}"
		extensionImgOverrides={{
			imgClasses: 'h-full w-full bg-white object-cover',
			wrapperClasses: 'aspect-video h-full w-full'
		}}
		{mode}
		placeholder="maincolor"
		ratio="16:9"
		src={path ?? ''}
		{title}
	/>
</figure>
