<script lang="ts">
	import { MARKETING_COPY } from '../../../copy';
	import AnimatedBorderCard from '../../elements/animated/AnimatedBorderCard.svelte';
	import Image from '../../elements/images/Image.svelte';

	export let background: null | string = null;
</script>

<AnimatedBorderCard
	{background}
	borderColor="primary"
	borderRadius="1.0rem"
	classes="!p-0"
	duration={8750}
	testid="cta-subscribe-card"
>
	<!-- TOP -->
	<div
		class="relative h-28 overflow-hidden after:absolute after:inset-0 after:bg-gradient-to-b after:from-transparent after:via-transparent after:to-surface-900"
	>
		<Image
			alt="chart page"
			anchor="top-left"
			classes="w-full"
			rounded="rounded-tl-container-token rounded-tr-container-token"
			src="/static/chart-page.png"
		/>
	</div>

	<!-- BOTTOM -->
	<div class="pb-2 pt-1 text-sm">
		<!-- <span class=" mt-2 block font-bold text-surface-50"> Sign up for Tickrr Today! </span>

		<p class="text-xs">
			{MARKETING_COPY.subscribeCard.text}
		</p> -->

		<div class="mb-4 px-1">
			<span class="block text-center text-xl font-bold text-surface-50"> 14 days free </span>
			<span class="block text-center text-sm">
				then {MARKETING_COPY.common.price} / month
			</span>
		</div>

		<div class="px-2">
			<a href="/subscribe" class="variant-filled btn w-full font-[500]">
				<span> Start trial </span>
			</a>
		</div>
	</div>
</AnimatedBorderCard>
