<script lang="ts">
	import EmptyState from '../misc/EmptyState.svelte';

	export let size: 'base' | 'lg' | 'sm' = 'base';
	export let description: string =
		'Please try refreshing the page. <br /> If the problem persists, please contact support using the button below.';
	export let classes: string = '';
</script>

<EmptyState icon="solar:bug-outline" {classes} {description} {size} title="An error occurred">
	<a
		href="mailto:support@tickrr.io"
		class="variant-filled btn"
		class:btn-lg={size === 'lg'}
		class:btn-sm={size === 'sm'}
	>
		<span> Contact support </span>
	</a>
</EmptyState>
