const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;
const ONE_MONTH_IN_MS = ONE_DAY_IN_MS * 30;
const ONE_YEAR_IN_MS = ONE_MONTH_IN_MS * 12;

/**
 * Calculates the time elapsed since a given date and returns a string representation of the elapsed time.
 * @param pastDate - The date to calculate the elapsed time from.
 * @returns A string representation of the elapsed time in days, hours, minutes, or seconds.
 */
export function calculateTimeSince(pastDate: Date | null | string): string {
	if (!pastDate) {
		return '?';
	}

	// Convert to Date object if necessary.
	if (typeof pastDate === 'string') {
		pastDate = new Date(pastDate);
	}

	const diff = Date.now() - pastDate.getTime();

	// Years.
	if (diff >= ONE_YEAR_IN_MS) {
		return `${Math.floor(diff / ONE_YEAR_IN_MS)}y`;
	}

	// Months.
	if (diff >= ONE_MONTH_IN_MS) {
		return `${Math.floor(diff / ONE_MONTH_IN_MS)}mo`;
	}

	// Days.
	if (diff >= ONE_DAY_IN_MS) {
		return `${Math.floor(diff / ONE_DAY_IN_MS)}d`;
	}
	// Hours.
	else if (diff >= ONE_HOUR_IN_MS) {
		return `${Math.floor(diff / ONE_HOUR_IN_MS)}h`;
	}
	// Minutes.
	else if (diff >= ONE_MINUTE_IN_MS) {
		return `${Math.floor(diff / ONE_MINUTE_IN_MS)}m`;
	}
	// Seconds.
	else if (diff >= ONE_SECOND_IN_MS) {
		return `${Math.floor(diff / ONE_SECOND_IN_MS)}s`;
	}
	// Default.
	// If we are defaulting to this, something has gone wrong.
	// However, the issue is generally with RSS feeds posting improper dates for
	// their articles. We check for this and prevent against it on the backend,
	// but it is still important to have a default case for the frontend here.
	return `1s`;
}
