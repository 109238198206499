import type { FEFeedItem } from '@tickrr/db';

/**
 * Retrieves the image path for a given feed item.
 *
 * @param item - The feed item to retrieve the image path for.
 * @param publisher - The publisher of the feed item.
 * @returns The image path if available, otherwise null.
 */
export const getFeedItemImagePath = (
	item: Pick<FEFeedItem, 'id' | 'img' | 'publisher' | 'type'>
): null | string => {
	switch (item.type) {
		// Daily discussions use the same image every time.
		case 'DAILY_DISCUSSION':
			return '/static/daily-discussion.webp';
		// Updates always have an image.
		case 'UPDATE':
			return `/feed-items/${item.id}`;
		// Posts might.
		case 'POST':
			return item.img ? `/feed-items/${item.id}` : null;
		// Articles might, but they have a fallback, if not.
		case 'ARTICLE':
			return item.img
				? `/feed-items/${item.id}`
				: `/avatars/${item.publisher?.default_post_img_url ?? item.publisher?.avatar_url}`;
		default:
			return null;
	}
};
