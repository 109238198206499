<!-- 
	@component
	
	AVATAR
	
	Displays a user's avatar image.	
	
	@prop width - The width of the avatar.
	@prop height - The height of the avatar.
	@prop rounded - The border radius of the avatar.
	@prop border - The border of the avatar.
	@prop classes - The classes to apply to the avatar.
	
	Note: 'group' is set in ./AvatarDropdown.svelte.
-->
<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import { page } from '$app/stores';

	import Avatar from './TwicAvatar.svelte';

	// Props...
	export let width: CssClasses = 'w-16';
	export let height: CssClasses = 'h-16';
	export let rounded: CssClasses = 'rounded-[10px]';
	export let border: CssClasses = 'border-2 border-base';
	export let classes: CssClasses = '';
</script>

<Avatar
	{border}
	classes="brightness-90 shine-on-hover transition-all overflow-hidden duration-300 ease-in-out hover:brightness-100 {classes}"
	{height}
	path={$page.data.profile?.avatar_url ?? 'default.png'}
	{rounded}
	{width}
	on:click
/>
